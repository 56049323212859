import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})

export class AlertService {

    constructor() { }

    fireAlert(title: string,
        icon: 'success' | 'error' | 'warning' | 'info' | 'question' = 'success',
        content: string | HTMLElement | undefined = undefined,
        html: boolean = false) {

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        if (html) {
            Toast.fire({
                icon: icon,
                title: title,
                html: content,
            });
        } else {
            Toast.fire({
                icon: icon,
                title: title,
                text: content ? content?.toString() : undefined,
            });
        }
    }
}
