import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    PATHAPI: string = 'api/user';

    constructor(private http: HttpClient) { }

    getAllData() {
       return this.http.get<any>(environment.URL_API + this.PATHAPI + '/getAll');
    }

    saveData(body: any) {
        return this.http.post<any>(environment.URL_API + this.PATHAPI + '/createUser', body);
    }

    updateData(body: any, id: any) {
        return this.http.patch<any>(environment.URL_API + this.PATHAPI + '/update/'+id, body);
    }

    changePassword(body: any, id: any) {
        return this.http.patch<any>(environment.URL_API + this.PATHAPI + '/changePassword/'+id, body);
    }
    deleteData(id: any) {
        return this.http.delete<any>(environment.URL_API + this.PATHAPI + '/delete/' + id);
    }
    getByID(id_user: any) {
        return this.http.get<any>(environment.URL_API + this.PATHAPI + '/getById/' + id_user);
    }

}
