import { AuthService } from './demo/service/auth.service';
import { TokensService } from './demo/service/tokens.service';
import { WebpushService } from './demo/service/webpush.service';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { environment } from 'src/environments/environment';
import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

    @HostListener('document:click', ['$event'])
    clickShowPassword(event: any) {
        const element = event?.target;
        const iElement = element?.tagName?.toLowerCase() === 'i' ? element :
            element?.firstChild?.tagName?.toLowerCase() === 'i' ? element.firstChild : null;
        if (iElement) {
            const classList = iElement.classList;
            if (classList.contains('pi-eye')) {
                classList.remove('pi-eye')
                classList.add('pi-eye-slash')
                let inputFind = null;
                let currentElement = iElement
                while (!inputFind) {
                    let parent = currentElement.parentElement;
                    if (parent.classList.contains('input-group')) {
                        parent = parent.querySelector('input[type="password"]');
                        if (parent) {
                            inputFind = parent;
                        }
                    }
                    currentElement = parent;
                }
                inputFind.type = 'text';
            }
            else if (classList.contains('pi-eye-slash')) {
                classList.remove('pi-eye-slash')
                classList.add('pi-eye')
                let inputFind = null;
                let currentElement = iElement
                while (!inputFind) {
                    let parent = currentElement.parentElement;
                    if (parent.classList.contains('input-group')) {
                        parent = parent.querySelector('input[type="text"]');
                        if (parent) {
                            inputFind = parent;
                        }
                    }
                    currentElement = parent;
                }
                inputFind.type = 'password';
            }
        }
    }
    message: any;
    tokenSuscribe: any;
    isLogin = false;
    isCapacitorPush = false;

    constructor(private primengConfig: PrimeNGConfig,
        private eRef: ElementRef, private webPush: WebpushService,
        private tokenS: TokensService,
        private auth: AuthService) { }
    ngAfterViewInit(): void {
        const loading: any = document.querySelector('#nb-global-spinner');
        console.log('loading', loading);
        if (loading) {
            setTimeout(()=> {
                loading.style.display = 'none';
            }, 1500);
        }
    }

    ngOnInit() {
        this.isCapacitorPush = Capacitor.isPluginAvailable('PushNotifications');
        console.log('this.isCapacitorPush', this.isCapacitorPush)
        this.primengConfig.ripple = true;
        // alert('init app');
        const userdata = this.auth.getUserData();
        if (userdata && Number(userdata.id) > 0) {
            this.isLogin = true;
        }
        if (this.isLogin) {
            if (this.isCapacitorPush) {
                this.requestNotifitationsMobile();
            } else {
                this.requestNotifitationsWeb();
            }
        }
        this.auth.currentUser.subscribe(data => {
            if (!this.isLogin && Number(data.id) > 0) {
                this.isLogin = true;
                // this.requestNotifitationsWeb();
                if (this.isCapacitorPush) {
                    this.requestNotifitationsMobile();
                } else {
                    this.requestNotifitationsWeb();
                }
            }
        });
    }

    requestNotifitationsWeb() {
        console.log('Pedir peticion de notificaciones');
        // alert('requestNotifitationsWeb');
        this.webPush.requestPermission();
    }

    requestNotifitationsMobile() {
        try {
            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting
            PushNotifications.requestPermissions().then(result => {
                if (result.receive === 'granted') {
                    console.log('granted');
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
                } else {
                    // Show some error
                }
            });

            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (token: Token) => {
                    // alert('Push registration success, token: ' + token.value);
                    this.tokenSuscribe?.unsubscribe();
                    const user = this.auth.getUserData();
                    const body = {
                        user_id: user.id,
                        token: token.value,
                    }
                    localStorage.setItem('currentToken', body.token);
                    console.log('data Save ===>', body);
                    this.tokenSuscribe = this.tokenS.saveData(body).subscribe(resp => {
                        console.log('respToken', resp)
                        this.tokenSuscribe.unsubscribe();
                    }, error => {
                        console.log('error al guardar token', error);
                        this.tokenSuscribe.unsubscribe();
                    });
                }
            );

            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    // alert('Error on registration: ' + JSON.stringify(error));
                    alert('No se pudo acceder a las notificaciones del sistema, por favor reinie la aplicación');
                }
            );

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotificationSchema) => {
                    // alert('Notificacion recivida abierta: ' + JSON.stringify(notification));
                    // this.webPush.drawAlertNotification(notification);
                }
            );

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    // alert('Tap notification: ' + JSON.stringify(notification));
                }
            );
        } catch (error) {
            console.log('error notification capacitor', error);
        }
    }

    // requestPermission() {
    //     const messaging = getMessaging();
    //     getToken(messaging,
    //         { vapidKey: environment.firebaseConfig.vapidKey }).then(
    //             (currentToken) => {
    //                 if (currentToken) {
    //                     console.log("Hurraaa!!! we got the token.....");
    //                     console.log(currentToken);
    //                     this.receiveMessages();
    //                 } else {
    //                     console.log('No registration token available. Request permission to generate one.');
    //                 }
    //             }).catch((err) => {
    //                 console.log('An error occurred while retrieving token. ', err);
    //             });
    // }


    // receiveMessages() {
    //     const messaging = getMessaging();
    //     onMessage(messaging, (payload) => {
    //         console.log('Message received. ', payload);
    //         this.message = payload;
    //     });
    // }
}
