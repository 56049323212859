<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <p-scrollPanel [style]="{width: '100%', height: '75vh'}" styleClass="custombar1">
                <router-outlet></router-outlet>
            </p-scrollPanel>
        </div>
        <app-footer></app-footer>
    </div>
    <!-- <app-config></app-config> -->
    <div class="layout-mask"></div>
</div>
