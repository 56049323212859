import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    PATHAPI: string = 'api/auth';
    public currentUser!: Observable<any>;
    private currentUserSubject!: BehaviorSubject<any>;

    constructor(private http: HttpClient) {
      this.currentUserSubject = new BehaviorSubject<any>(localStorage.getObject('userData') || {});
      this.currentUser = this.currentUserSubject.asObservable();
    }

    login(user: string, password: string) {
        const body = {
            mail: user,
            password: password,
        }
        return this.http.post<any>(environment.URL_API + this.PATHAPI + '/login', body);
    }

    setUserData(userData: any) {
        // console.log('setUserData')
        localStorage.setObject('userData', userData);
        // console.log(this.getUserData())
        this.currentUserSubject.next(this.getUserData());
        return true;
    }

    isAdmin() {
        const userData = localStorage.getObject('userData');
        if (userData && userData.Type && userData.Type === 'admin') {
            return true;
        }
        return false;
    }

    getUserData() {
        return localStorage.getObject('userData');
    }
}
