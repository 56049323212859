import { AuthService } from './auth.service';
import { UserService } from 'src/app/demo/service/user.service';
import { AlertService } from './alert.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AuthGuardService implements CanActivate  {

    constructor(private router: Router, private alertS: AlertService,
        private userService: UserService, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const url: any = route.url;
        return this.checkUserLogin(route, url);
    }

    async checkUserLogin(route: ActivatedRouteSnapshot, url: any) {
        console.log('URL', url);
        console.log(route);
        const rolValidate: any = route.data;
        // localStorage.setObject('userData', {user:'test', type: 'admin'});
        let userDataLocal = this.authService.getUserData();
        // console.log('userDataLocal', userDataLocal)
        if (userDataLocal && userDataLocal.id)
        {
            const refreshData = await firstValueFrom(this.userService.getByID(userDataLocal.id));
            if (refreshData && refreshData.data && refreshData.data.id === userDataLocal.id) {
                userDataLocal = refreshData.data;
                // localStorage.setObject('userData', refreshData);
                this.authService.setUserData(refreshData.data);
            }
            // console.log('userDataLocal', userDataLocal);
            if (rolValidate && rolValidate?.rolespermission) {
                console.log('rolValidate', rolValidate)
                const roles = rolValidate.rolespermission;
                // console.log('roles', roles)
                if (roles.includes(userDataLocal.Type)) {
                    return true
                }
                this.router.navigate(['/']);
                this.alertS.fireAlert('No se pudo acceder a la ruta', 'error','Usuario no autorizado');
                return false;
            }
            return true;
        }
        this.router.navigate(['/login']);
        // this.alertS.fireAlert('No se pudo acceder a la ruta', 'error','Usuario no autorizado');
        return false;
    }
}
