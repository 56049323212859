export { } // this will make it module
declare global {
  interface Storage {
    setObject(key: string, value: any): any;
    getObject(key: string): any;
  }
}

Storage.prototype.setObject = function (key: string, value: any) {
  console.log('guardar Objeto')
  this.setItem(key, JSON.stringify(value));
}

Storage.prototype.getObject = function (key: string) {
  const value = this.getItem(key);
  return value && JSON.parse(value);
}
