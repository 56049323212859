import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { Table } from 'primeng/table';
import { AlertService } from 'src/app/demo/service/alert.service';
import { UserService } from 'src/app/demo/service/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    cols: any = [
        { field: 'Name', header: 'Nombre' },
        { field: 'Email', header: 'Email' },
        { field: 'TypeString', header: 'Tipo' },
        { field: 'Active', header: 'Activo', textClass: {
            1: 'text-green-700',
            0: 'text-red-700',
        }},
    ];

    users: any = [];
    selectID: any;
    editMode = false;
    editData: any = {
        Name: '',
        Email: '',
        Password: '',
        Type: 'user',
        Active: 1,
    };
    textTitle: any = '';
    editPassword = '';
    // nameUser = '';
    titleModal = '';
    Status: any = [{name: 'Activo', value: 1}, {name: 'Inactivo', value: 0}];
    TypeUser: any = [{name: 'Usuario', value: 'user'}, {name: 'Administrador', value: 'admin'}];

    @ViewChild('deleteSwal') deleteSwal: any;
    @ViewChild('createSwal') createSwal: any;
    @ViewChild('changePasswordSwal') changePasswordSwal: any;
    @ViewChild('usersTable') usersTable: any | Table;

    constructor(
        public readonly swalTargets: SwalPortalTargets,
        private userService: UserService,
        private alertService: AlertService
    ) {

    }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.userService.getAllData().subscribe(data => {
            if (data?.success) {
                this.users = data?.data || [];
                this.users.map((x: any) => {
                    x.ActiveString = x.Active === true
                    || x.Active === 1 ? 'Activo' : 'Inactivo';
                    x.TypeString = x.Type === 'admin' ? 'Administrador' : 'Usuario';
                    return x;
                })
                console.log('tanks', this.users);
            }
        })
    }

    deleteData(id: any) {
        if (id && Number(id) > 0) {
            this.userService.deleteData(id).subscribe((data) => {
                if (data.success) {
                    this.alertService.fireAlert('Acción realizada con éxito', 'success', data?.message || '')
                } else {
                    this.alertService.fireAlert('Acción no completada', 'error', data?.message || '')
                }
                this.loadData();
            }, err => {
                console.log('err', err);
                this.alertService.fireAlert('Error al eliminar datos del usuario', 'error')
            })
        }
    }
    restoreData() {
        this.editMode = false;
        this.editData = {
            Name: '',
            Email: '',
            Password: '',
            Type: 'user',
            Active: 1,
        };
        this.editPassword = '';
        console.log('Teeest')
        this.titleModal = '';
        this.selectID = null;
    }
    saveData() {
        if (this.editData?.Password && this.editData?.Password.trim() === '') {
            delete this.editData.Password;
        }
        this.editData.Active = this.editData.Active === 1 ? true : false;
        if (this.editMode && this.editData.id && Number(this.editData.id) > 0) {
            this.userService.updateData(this.editData, this.editData.id).subscribe((data) => {
                if (data.success) {
                    this.alertService.fireAlert('Acción realizada con éxito', 'success', data?.message || '')
                } else {
                    this.alertService.fireAlert('Acción no completada', 'error', data?.message || '')
                }
                this.loadData();
            }, err => {
                console.log('err', err);
                this.alertService.fireAlert('Error al modificar datos del usuario', 'error')
            })
        } else {
            this.userService.saveData(this.editData).subscribe(data => {
                if (data.success) {
                    this.alertService.fireAlert('Acción realizada con éxito', 'success', data?.message || '')
                } else {
                    this.alertService.fireAlert('Acción no completada', 'error', data?.message || '')
                }
                this.loadData();
            }, err => {
                console.log('err', err);
                this.alertService.fireAlert('Error al guardar datos del usuario', 'error')
            })
        }
        console.log('editData', this.editData)

    }
    openDeleteModal(id: any, name: any = '') {
        if (id) {
            console.log('name', name)
            this.textTitle = '¿Seguro que desea eliminar al usuario ' + name + '?';
            this.selectID = id;
            this.deleteSwal.title = this.textTitle;
            this.deleteSwal.fire();
        }
    }

    openPasswordModal(id: any, name: any = '') {
        if (id) {
            this.editPassword = '';
            this.textTitle = '¿Seguro que desea cambiar contraseña del usuario ' + name + '?';
            this.selectID = id;
            this.changePasswordSwal.title = this.textTitle;
            this.changePasswordSwal.fire();
        }
    }

    changeUserPassword(id: any) {
        if (id && Number(id) > 0) {
            this.userService.changePassword(this.editData, id).subscribe((data) => {
                if (data.success) {
                    this.alertService.fireAlert('Acción realizada con éxito', 'success', data?.message || '')
                } else {
                    this.alertService.fireAlert('Acción no completada', 'error', data?.message || '')
                }
                this.loadData();
            }, err => {
                console.log('err', err);
                this.alertService.fireAlert('Error al modificar contraseña del usuario', 'error')
            })
        }
    }

    showModal(datos: any = null) {
        this.textTitle = 'Crear nuevo usuario';
        if (datos) {
            this.editData = {...datos};
            this.editData.Active = this.editData.Active === true
            || this.editData.Active === 1 ? 1 : 0;
            this.editMode = true;
            this.textTitle = 'Editar datos de usuario';
        }
        this.createSwal.title = this.textTitle;
        this.createSwal.fire();
    }

    getFilter(e: any) {
        return this.usersTable.filterGlobal(e.target.value, 'contains');
    }
}
