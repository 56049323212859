<div class="row">
    <div class="col-12">
        <p-table
            #tanksTable
            [value]="tanks"
            styleClass="p-datatable-striped"
            dataKey="id"
            [rows]="4"
            [showCurrentPageReport]="true"
            [paginator]="true"
            [globalFilterFields]="['Name', 'Code', 'ActiveString']"
        >
            <ng-template pTemplate="caption">
                <div class="row py-0">
                    <div class="col-6 text-start py-0">
                        Lista de Cisternas<br/>
                        <button
                            pButton
                            pRipple
                            type="button"
                            label="Crear Cisterna"
                            class="p-button-sm p-button-outlined p-button-success"
                            icon="pi pi-plus"
                            (click)="showModal()"
                        ></button>
                    </div>
                    <div class="col-6 text-end py-0">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                class="w-100"
                                (input)="getFilter($event)"
                                placeholder="Buscar..."
                            />
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" class="text-center">
                        {{ col.header }}
                    </th>
                    <th class="text-center">Acciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-car let-editing="editing">
                <tr>
                    <td
                        *ngFor="let col of cols"
                        class="{{
                            col.textClass && col.field === 'Active'
                                ? col.textClass[car[col.field].toString()] || ''
                                : ''
                        }} text-center"
                    >
                        <label *ngIf="col.field === 'Active'"
                            ><b>{{
                                car[col.field] === 1 ? "Activo" : "Inactivo"
                            }}</b></label
                        >
                        <label *ngIf="col.field !== 'Active'">{{
                            car[col.field]
                        }}</label>
                    </td>
                    <td class="text-center">
                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-pencil"
                            class="m-1 p-button-outlined p-button-info p-button-sm"
                            (click)="showModal(car)"
                        ></button>
                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-trash"
                            (click)="openDeleteModal(car?.id)"
                            class="m-1 p-button-outlined p-button-danger p-button-sm"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<swal
    #createSwal
    title="{{textTitle}}"
    (confirm)="saveData()"
    (didDestroy)="restorezIndex()"
    width="450"
>
    <div *swalPortal="swalTargets.content">
        <div class="form-group my-2 text-start">
            <label for="nameTank">Nombre</label>
            <input
                type="text"
                name="nameTank"
                class="form-control"
                id="nameTank"
                aria-describedby="nameTank"
                placeholder="Ingrese nombre de cisterna"
                [(ngModel)]="editData.Name"
            />
        </div>
        <div class="form-group my-2 text-start">
            <label for="codeTank">Código</label>
            <input
                type="text"
                class="form-control"
                name="codeTank"
                id="codeTank"
                placeholder="Inggrese codigo de cisterna"
                [(ngModel)]="editData.Code"
            />
        </div>
        <div class="form-group my-2 text-start" *ngIf="editMode">
            <label for="activeTank">Estado</label>
            <select
                class="form-control"
                id="statusTank"
                [(ngModel)]="editData.Active"
            >
                <option
                    *ngFor="let item of Status"
                    [ngValue]="item.value"
                    [selected]="editData.Active === item.value"
                >
                    {{ item.name }}
                </option>
            </select>
        </div>
    </div>
    <ng-container *swalPortal="swalTargets.confirmButton">
        <i class="pi pi-save"></i>&nbsp;&nbsp;{{editMode ? 'Actualizar': 'Guardar'}}
    </ng-container>
    <ng-container *swalPortal="swalTargets.cancelButton">
        <i class="pi pi-times"></i>&nbsp;&nbsp;Cancelar
    </ng-container>
</swal>

<swal
    #deleteSwal
    (confirm)="deleteData(selectID)"
    (didDestroy)="selectID = null"
    width="450"
    title="Seguro que desea eliminar esta cisterna?"
>
    <ng-container *swalPortal="swalTargets.confirmButton">
        <i class="pi pi-check"></i>&nbsp;&nbsp;Sí, Eliminar
    </ng-container>
    <ng-container *swalPortal="swalTargets.cancelButton">
        <i class="pi pi-times"></i>&nbsp;&nbsp;No, Cancelar
    </ng-container>
</swal>
