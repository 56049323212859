import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TokensService {
    PATHAPI: string = 'api/notifications';

    constructor(private http: HttpClient) { }

    saveData(body: any) {
        return this.http.post<any>(environment.URL_API + this.PATHAPI + '/create', body);
    }

    deleteToken(token: any) {
        return this.http.post<any>(environment.URL_API + this.PATHAPI + '/delete', {token});
    }

}
