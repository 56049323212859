<div class="row">
    <div class="col-12">
        <p-table
            #assignmentTable
            [value]="assignmentsList"
            styleClass="p-datatable-striped"
            dataKey="id"
            [rows]="4"
            [showCurrentPageReport]="true"
            [paginator]="true"
            [globalFilterFields]="['Name', 'Code', 'ActiveString']"
        >
            <ng-template pTemplate="caption">
                <div class="row py-0">
                    <div class="col-6 text-start py-0">
                        Asignacion<br />
                        <button
                            pButton
                            pRipple
                            type="button"
                            label="Asignar cisterna a usuario"
                            class="p-button-sm p-button-outlined p-button-success"
                            icon="pi pi-plus"
                            (click)="showModal()"
                        ></button>
                        <!-- (click)="showModal()" -->
                    </div>
                    <div class="col-6 text-end py-0">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                class="w-100"
                                (input)="getFilter($event)"
                                placeholder="Buscar..."
                            />
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" class="text-center">
                        {{ col.header }}
                    </th>
                    <th class="text-center">Acciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-car let-editing="editing">
                <tr>
                    <td
                        *ngFor="let col of cols"
                        class="{{
                            col.textClass && col.field === 'Active'
                                ? col.textClass[car[col.field].toString()] || ''
                                : ''
                        }} text-center"
                    >
                        <label *ngIf="col.field === 'Active'"
                            ><b>{{
                                car[col.field] === 1 ? "Activo" : "Inactivo"
                            }}</b></label
                        >
                        <label *ngIf="col.field !== 'Active'">{{
                            car[col.field]
                        }}</label>
                    </td>
                    <td class="text-center">
                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-pencil"
                            class="m-1 p-button-outlined p-button-info p-button-sm"
                            (click)="showModal(car)"
                        ></button>

                        <!-- (click)="showModal(car)" -->
                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-trash"
                            class="m-1 p-button-outlined p-button-danger p-button-sm"
                            (click)="openDeleteModal(car?.id)"
                        ></button>

                        <!-- (click)="openDeleteModal(car?.id)" -->
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<swal #createSwal width="550" title="Seguro que desea eliminar esta cisterna?"
[preConfirm]="saveData"
(confirm)="successFullSave($event)"
target="body"
>
    <ng-container *swalPortal="swalTargets.content" class="h-modal">
        <div class="form-group my-2 text-start">
            <label for="activeTank">Cisterna</label>
            <p-dropdown dataKey="id" styleClass="form-control vh-6" [options]="tanks" [(ngModel)]="editData.tank_id" optionLabel="Name" optionValue="id"
            appendTo="body" placeholder="Seleccione Cisterna" [baseZIndex]="99999" [filter]="true" filterBy="Name" [showClear]="false">
            <ng-template pTemplate="filter" let-options="options">
                <div class="input-group" (click)="$event.stopPropagation()">
                    <input type="text" class="form-control" aria-describedby="clear" [(ngModel)]="filterValue" (keyup)="options.filter($event)">
                    <span class="input-group-text clearButton text-danger" id="clear" *ngIf="filterValue && filterValue !== ''" (click)="myResetFunction(options)"><i class="pi pi-times"></i></span>
                </div>
            </ng-template>
            </p-dropdown>
        </div>
        <div class="form-group my-2 text-start">
            <label for="activeTank">Usuario</label>
            <p-dropdown styleClass="form-control vh-6" [options]="users" [(ngModel)]="editData.user_id" optionLabel="Name" optionValue="id"
            appendTo="body" placeholder="Seleccione Usuario" [baseZIndex]="99999" [filter]="true" filterBy="Name" [showClear]="false">
            <ng-template pTemplate="filter" let-options="options">
                <div class="input-group" (click)="$event.stopPropagation()">
                    <input type="text" class="form-control" aria-describedby="clear" [(ngModel)]="filterValue" (keyup)="options.filter($event)">
                    <span class="input-group-text clearButton text-danger" id="clear" *ngIf="filterValue && filterValue !== ''" (click)="myResetFunction(options)"><i class="pi pi-times"></i></span>
                </div>
            </ng-template>
            </p-dropdown>
        </div>
    </ng-container>
    <ng-container *swalPortal="swalTargets.confirmButton">
        <i class="pi pi-save"></i>&nbsp;&nbsp;{{editMode ? 'Modificar':'Guardar'}}
    </ng-container>
    <ng-container *swalPortal="swalTargets.cancelButton">
        <i class="pi pi-times"></i>&nbsp;&nbsp;Cancelar
    </ng-container>
</swal>

<swal #deleteSwal width="450" title="Seguro que desea eliminar esta cisterna?" (confirm)="deleteData(idDelete)">
    <ng-container *swalPortal="swalTargets.confirmButton">
        <i class="pi pi-check"></i>&nbsp;&nbsp;Sí, Eliminar
    </ng-container>
    <ng-container *swalPortal="swalTargets.cancelButton">
        <i class="pi pi-times"></i>&nbsp;&nbsp;No, Cancelar
    </ng-container>
</swal>
