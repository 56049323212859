import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TanksService {
    PATHAPI: string = 'api/tanks';

    constructor(private http: HttpClient) { }

    getAllData() {
        return this.http.get<any>(environment.URL_API + this.PATHAPI + '/getAll');
    }

    getAllActiveTanks() {
        return this.http.get<any>(environment.URL_API + this.PATHAPI + '/getActives');
    }

    saveData(body: any) {
        return this.http.post<any>(environment.URL_API + this.PATHAPI + '/createTank', body);
    }

    updateData(body: any, id: any) {
        return this.http.patch<any>(environment.URL_API + this.PATHAPI + '/update/' + id, body);
    }
    deleteData(id: any) {
        return this.http.delete<any>(environment.URL_API + this.PATHAPI + '/delete/' + id);
    }

}
