import { AlertService } from './alert.service';
import { TokensService } from './tokens.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
// import {getMessaging, getToken, onMessage} from '@angular/fire/messaging';
// import { environment } from 'src/environments/environment';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable({
  providedIn: 'root'
})
export class WebpushService {
  tokenSuscribe: any;
  constructor(private angularFireMessaging: AngularFireMessaging, private auth: AuthService,
    private tokenS: TokensService, private alertS: AlertService) { }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(token => {
        console.log('Token', token);
        if (token) {
            const user = this.auth.getUserData();
            const body = {
                user_id: user.id,
                token,
            }
            localStorage.setItem('currentToken', token);
            this.tokenSuscribe = this.tokenS.saveData(body).subscribe(resp => {
                console.log('respToken', resp)
                this.tokenSuscribe.unsubscribe();
            }, error => {
                console.log('error al guardar token', error);
                this.tokenSuscribe.unsubscribe();
            });
            this.receiveMessages();
        }
    }, error => {
        console.log('error', error);
    })
  }

  receiveMessages() {
    console.log('receiveMessages')
    this.angularFireMessaging.messages.subscribe(msg => {
        console.log('receiveMessagesx2')
        console.log('msg ===>', msg);
    });
  }

  drawAlertNotification(notification: any) {
    const body = notification?.body || null;
    if (body) {
        this.alertS.fireAlert(body?.title || '', 'info', body?.body || '');
    }
  }
}
