import { WebpushService } from './demo/service/webpush.service';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import { IonicModule } from '@ionic/angular';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { UsersComponent } from './demo/components/admin/users/users.component';
import { AssignmentComponent } from './demo/components/admin/assignment/assignment.component';
import { TankComponent } from './demo/components/admin/tank/tank.component';
import { TableModule } from 'primeng/table';
import { BrowserModule } from '@angular/platform-browser'
import { ButtonModule } from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatSelectModule } from '@angular/material/select';
import {InputTextModule} from 'primeng/inputtext';
import {TooltipModule} from 'primeng/tooltip';
import { environment } from "../environments/environment";
import {MatFormFieldModule} from '@angular/material/form-field';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';


import './global';

import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
const config: SocketIoConfig = {
    url: environment.URL_SOCKET,
    // options: { path: environment.isLocalProd ? '/bi-apis/socket.io' : '/socket.io'},
};
@NgModule({
    declarations: [
        AppComponent, NotfoundComponent, UsersComponent, AssignmentComponent, TankComponent
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        ScrollPanelModule,
        IonicModule.forRoot(),
        TableModule,
        BrowserModule,
        ButtonModule,
        HttpClientModule,
        SocketIoModule.forRoot(config),
        DialogModule,
        DropdownModule,
        FormsModule,
        InputTextModule,
        SweetAlert2Module.forRoot({
            provideSwal: () => import('sweetalert2').then(({ default: swal }) => swal.mixin({
                // example: set global options here
                customClass: {
                    confirmButton: 'btn btn-success mx-1',
                    cancelButton: 'btn btn-danger mx-1'
                },
                buttonsStyling: false
            }))
        }),
        MatSelectModule,
        TooltipModule,
        MatFormFieldModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig)
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService,
        WebpushService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
