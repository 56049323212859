<div class="layout-topbar">
    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/aquaher.svg" alt="logo">
        <!-- <span>AQUAHER</span> -->
    </a>



    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>
    <div #topbarmenu class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">

        <button class="p-link mx-1" [disabled]="true" style="color: #000 !important;">
            <span>{{this.currentUser.Name}}</span>
        </button>
        <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </button> -->
        <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button> -->
        <button class="p-link layout-topbar-button" (click)="logout()">
            <i class="pi pi-sign-out"></i>
            <span>Cerrar Sesion</span>
        </button>
    </div>
</div>
