// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL_API: 'https://api.aquaher.com/',
  URL_SOCKET: 'https://api.aquaher.com/',
  firebaseConfig: {
    apiKey: "AIzaSyA73jBCKXOUkfu6DQwEJFLtk07KCgsV6dM",
    authDomain: "mqtt-app-b0c0d.firebaseapp.com",
    databaseURL: "https://mqtt-app-b0c0d-default-rtdb.firebaseio.com",
    projectId: "mqtt-app-b0c0d",
    storageBucket: "mqtt-app-b0c0d.appspot.com",
    messagingSenderId: "996684127095",
    appId: "1:996684127095:web:ff5fb11b3a7ed6c9a91f72",
    measurementId: "G-98WZ76REF9"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
