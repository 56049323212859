<div class="row">
    <div class="col-12">
        <p-table
            #usersTable
            [value]="users"
            styleClass="p-datatable-striped"
            dataKey="id"
            [rows]="4"
            [showCurrentPageReport]="true"
            [paginator]="true"
            [globalFilterFields]="['Name', 'Code', 'ActiveString']"
        >
            <ng-template pTemplate="caption">
                <div class="row py-0">
                    <div class="col-6 text-start py-0">
                        <i class="pi pi-users"></i>&nbsp;Lista de Usuarios<br />
                        <button
                            pButton
                            pRipple
                            type="button"
                            label="Crear Usuario"
                            class="p-button-sm p-button-outlined p-button-success"
                            icon="pi pi-user-plus"
                            (click)="showModal()"
                        ></button>
                    </div>
                    <div class="col-6 text-end py-0">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                class="w-100"
                                (input)="getFilter($event)"
                                placeholder="Buscar..."
                            />
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" class="text-center">
                        {{ col.header }}
                    </th>
                    <th class="text-center">Acciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-car let-editing="editing">
                <tr>
                    <td
                        *ngFor="let col of cols"
                        class="{{
                            col.textClass && col.field === 'Active'
                                ? col.textClass[car[col.field].toString()] || ''
                                : ''
                        }} text-center"
                    >
                        <label *ngIf="col.field === 'Active'"
                            ><b>{{
                                car[col.field] === 1 ? "Activo" : "Inactivo"
                            }}</b></label
                        >
                        <label *ngIf="col.field !== 'Active'">{{
                            car[col.field]
                        }}</label>
                    </td>
                    <td class="text-center">
                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-user-edit"
                            class="m-1 p-button-outlined p-button-info p-button-sm"
                            (click)="showModal(car)"
                        ></button>
                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-user-minus"
                            (click)="openDeleteModal(car?.id, car?.Name)"
                            class="m-1 p-button-outlined p-button-danger p-button-sm"
                        ></button>

                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-lock-open"
                            (click)="openPasswordModal(car?.id, car?.Name)"
                            class="m-1 p-button-outlined p-button-warning p-button-sm"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<swal
    #createSwal
    (confirm)="saveData()"
    (didDestroy)="restoreData()"
    width="450"
    title=""
>
    <div *swalPortal="swalTargets.content">
        <div class="form-group my-2 text-start">
            <label for="nameTank">Nombre</label>
            <input
                type="text"
                name="nameUser"
                class="form-control"
                id="nameUser"
                autocomplete="nameUser"
                aria-describedby="nameUser"
                placeholder="Ingrese nombre de usuario"
                [(ngModel)]="editData.Name"
            />
        </div>
        <div class="form-group my-2 text-start">
            <label for="userEmail">Email</label>
            <input
                type="text"
                class="form-control"
                name="userEmail"
                id="userEmail"
                autocomplete="userEmail"
                placeholder="Inggrese correo de usuario"
                [(ngModel)]="editData.Email"
            />
        </div>
        <div class="form-group my-2 text-start" *ngIf="!editMode">
            <label for="userPassword">Contraseña</label>
            <div class="input-group my-2 text-start">
                <input
                    type="password"
                    autocomplete="userPassword"
                    name="userPassword"
                    class="form-control"
                    placeholder="Contraseña"
                    aria-label="Contraseña"
                    aria-describedby="userPassword"
                    [(ngModel)]="editData.Password"
                />
                <span class="input-group-text" id="userPassword"
                    ><i class="pi pi-eye"></i
                ></span>
            </div>
        </div>

        <div class="form-group my-2 text-start">
            <label for="activeTank">Tipo de Usuario</label>
            <select
                class="form-control"
                id="typeUser"
                name="typeUser"
                [(ngModel)]="editData.Type"
            >
                <option
                    *ngFor="let item of TypeUser"
                    [ngValue]="item.value"
                    [selected]="editData.Type === item.value"
                >
                    {{ item.name }}
                </option>
            </select>
        </div>
        <div class="form-group my-2 text-start" *ngIf="editMode">
            <label for="activeTank">Estado</label>
            <select
                class="form-control"
                id="statusUser"
                autocomplete="statusUser"
                [(ngModel)]="editData.Active"
            >
                <option
                    *ngFor="let item of Status"
                    [ngValue]="item.value"
                    [selected]="editData.Active === item.value"
                >
                    {{ item.name }}
                </option>
            </select>
        </div>
    </div>
    <ng-container *swalPortal="swalTargets.confirmButton">
        <i class="pi pi-save"></i>&nbsp;&nbsp;{{
            editMode ? "Actualizar" : "Guardar"
        }}
    </ng-container>
    <ng-container *swalPortal="swalTargets.cancelButton">
        <i class="pi pi-times"></i>&nbsp;&nbsp;Cancelar
    </ng-container>
</swal>

<swal
    #deleteSwal
    (confirm)="deleteData(selectID)"
    (didDestroy)="restoreData()"
    width="450"
    title=""
>
    <ng-container *swalPortal="swalTargets.confirmButton">
        <i class="pi pi-check"></i>&nbsp;&nbsp;Sí, Eliminar
    </ng-container>
    <ng-container *swalPortal="swalTargets.cancelButton">
        <i class="pi pi-times"></i>&nbsp;&nbsp;No, Cancelar
    </ng-container>
</swal>
<swal
    #changePasswordSwal
    (confirm)="changeUserPassword(selectID)"
    width="450"
    (didDestroy)="restoreData()"
    title=""
>
    <ng-container *swalPortal="swalTargets.content">
        <div class="form-group my-2 text-start">
            <label for="userPassword">Contraseña</label>
            <div class="input-group my-2 text-start">
                <input
                    type="password"
                    autocomplete="userPassword"
                    name="userPassword"
                    class="form-control"
                    placeholder="Contraseña"
                    aria-label="Contraseña"
                    aria-describedby="userPassword"
                    [(ngModel)]="editData.Password"
                />
                <span class="input-group-text" id="userPassword"
                    ><i class="pi pi-eye"></i
                ></span>
            </div>
        </div>
    </ng-container>
    <ng-container *swalPortal="swalTargets.confirmButton">
        <i class="pi pi-save"></i>&nbsp;&nbsp;Cambiar Contraseña
    </ng-container>
    <ng-container *swalPortal="swalTargets.cancelButton">
        <i class="pi pi-times"></i>&nbsp;&nbsp;Cancelar
    </ng-container>
</swal>
