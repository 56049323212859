import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { UsersComponent } from './demo/components/admin/users/users.component';
import { TankComponent } from './demo/components/admin/tank/tank.component';
import { AssignmentComponent } from './demo/components/admin/assignment/assignment.component';
import { AuthGuardService } from './demo/service/authguard.service';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppLayoutComponent,
                children: [
                    {
                        path: ''
                        , canActivate: [AuthGuardService]
                        , loadChildren: () => import('./demo/components/dashboard/dashboard.module').then(m => m.DashboardModule)
                    },
                    // { path: 'uikit', loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
                    // { path: 'utilities', loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
                    // { path: 'documentation', loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
                    // { path: 'blocks', loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
                    // { path: 'pages', loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) }
                ]
            },
            {
                path: 'admin'
                , canActivate: [AuthGuardService]
                , data: {
                    rolespermission: ['admin']
                }
                , component: AppLayoutComponent,
                children: [
                    {
                        path: 'users'
                        , canActivate: [AuthGuardService]
                        , data: {
                            rolespermission: ['admin']
                        }
                        , component: UsersComponent
                    },
                    {
                        path: 'tanks'
                        , canActivate: [AuthGuardService]
                        , data: {
                            rolespermission: ['admin']
                        }
                        , component: TankComponent
                    },
                    {
                        path: 'assignment'
                        , canActivate: [AuthGuardService]
                        , data: {
                            rolespermission: ['admin']
                        }
                        , component: AssignmentComponent
                    }
                ]
            },
            { path: 'login', loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
            // { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
