import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AssignmentService {
    PATHAPI: string = 'api/assignment';

    constructor(private http: HttpClient) { }
    getAllData() {
        return this.http.get<any>(environment.URL_API + this.PATHAPI + '/getAll');
    }
    saveData(body: any) {
        return this.http.post<any>(environment.URL_API + this.PATHAPI + '/create', body);
    }
    updateData(body: any, id: any) {
        return this.http.patch<any>(`${environment.URL_API}${this.PATHAPI}/update/${id}`, body);
    }
    getByUserID(user_id: any) {
        return this.http.get<any>(environment.URL_API + this.PATHAPI + '/getByUser/' + user_id);
    }
    deleteData(id_assigment: any) {
        return this.http.delete<any>(`${environment.URL_API}${this.PATHAPI}/deleteAssigment/${id_assigment}`);
    }
}
